import State from "../state/state";
import { dp_month_array_full } from "../irv/ivr";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const tooltipMap = require("../config/json/tooltip.json");
const suggestionMap = require("../config/json/mapping.json");
const beneficiaryMap = require("../config/json/beneficiaryKeys.json");
const stateConfig = require("../config/json/stateConfig.json");
const marketplaceDtls = require("../config/json/marketplacedtls.json");
const linkSuggestionMap = require("../config/json/link-mapping.json");
const privateInsuranceList = require("../config/json/privateInsurance.json");
const community = require("../config/json/community.json");

const current_date = new Date();
const state = State.getInstance();

export class Util {
  getRandomSessionId() {
    return Math.random().toString(36).substr(2, 9);
  }

  getInputType(msgArr) {
    msgArr = msgArr.filter((msg) => msg.message === "suggestions");

    if (msgArr.length === 0) {
      return "text";
    } else if (msgArr[0].suggestions.suggestions) {
      const suggestions = this.getSuggestionList(msgArr);
      if (suggestions.indexOf("select Dob") !== -1) {
        return "dob";
      } else if (suggestions.indexOf("Select State") !== -1) {
        return "state-dropdown";
      } else if (suggestions.indexOf("multiselect") !== -1) {
        return "multiselect";
      } else {
        return "suggestions";
      }
    }
  }

  getSuggestionList(msgArr) {
    let suggestionArr = msgArr[0].suggestions.suggestions.map((json) => {
      let title = json.title;
      if (title.indexOf("<yyyy>") !== -1) {
        title = title.replace("<yyyy>", new Date().getFullYear());
      }
      return title;
    });
    if (suggestionArr[0] === "DEPENDANTS-COUNT") {
      suggestionArr = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    } else if (suggestionArr[0] === "INCOME-RANGE") {
      suggestionArr = this.getIncomeRange();
    } else if (suggestionArr[0] === "MEDICAID-STATES") {
      suggestionArr = ["Select State"];
    } else if (suggestionArr.includes("Private_Insurance")) {
      let list = Array.from(state.privateInsurance);
      suggestionArr = list.splice(0, 3);
      suggestionArr.push("Private_Insurance");
    }
    return this.checkNreplaceIds(suggestionArr);
  }

  getSuggestions(msgArr, intentName) {
    // used for audio
    msgArr = msgArr.filter((msg) => msg.message === "suggestions");
    const inputType = Util.getInstance().getInputType(msgArr);
    if (inputType !== "suggestions" && inputType !== "multiselect") {
      return null;
    }
    let suggestions = Util.getInstance().getSuggestionList(msgArr);

    if (inputType === "multiselect") {
      suggestions = suggestions.filter((suggestion) => {
        return suggestion !== "multiselect";
      });
    }
    if (suggestions.length < 2) {
      return null;
    }
    if (intentName === state.dependantsIntent) {
      return null;
    }
    if (
      suggestions[0] === "Type Email id" ||
      suggestions[0] === "Click to type Email id"
    ) {
      suggestions = null;
    }
    return this.checkNreplaceIds(suggestions);
  }

  getIncomeRange() {
    if (!state.stateJson.dependants || !state.stateJson.state) {
      return [];
    }
    let suggestionArr = [];
    stateConfig.forEach((json) => {
      if (json.states.indexOf(state.stateJson.state) !== -1) {
        suggestionArr = json[state.stateJson.dependants];
      }
    });
    return suggestionArr;
  }

  getInsurancetype() {
    let typeArr = [];
    if(Array.isArray(state.stateJson.certification)){
      state.stateJson.certification.forEach((certificate)=> {
        if(certificate === "Medicare only"){
          typeArr.push('Medicare')
        } else if(certificate === "Medicaid only"){
          typeArr.push('Medicaid')
        } else {
          typeArr.push('Medicare')
          typeArr.push('Medicaid')
          typeArr.push('Medicare and Medicaid')
        }
      })
    }
    return typeArr;
  }

   Objclean (obj) {
    for (let propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        delete obj[propName];
      }
    }
    return obj
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
    }
  
  getCodeFromValue(value, objs) {
    const obj = objs;
    return Object.keys(obj).find((key) => obj[key] === value);
  }

  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  checkSuccess(message) {
    let res = {
      message: message,
      class: "",
    };

    if (message.indexOf("#SUCCESS#") !== -1) {
      res.message = message.replace("#SUCCESS#", "");
      res.class = "positive-result";
      // Util.getInstance().updateProgress(true);
    } else if (
      message.indexOf("<FAILURE>") !== -1 ||
      message.indexOf("#Error") !== -1 ||
      message.indexOf("#ERROR#") !== -1
    ) {
      message = message.replace("<FAILURE>", "");
      message = message.replace("#ERROR#", "");
      message = message.replace("#Error#", "");
      res.message = message;
      res.class = "negative-result";
      // Util.getInstance().updateProgress(true);
    }
    return res;
  }

  checkNreplaceIds(suggestionList) {
    if (suggestionList && suggestionList.indexOf("IDs") !== -1) {
      suggestionList = suggestionList.filter(
        (suggestion) => suggestion !== "IDs"
      );
      suggestionList = suggestionList.map((suggestion) => {
        if (suggestionMap[suggestion]) {
          return suggestionMap[suggestion];
        }
        return suggestion;
      });
    }
    return suggestionList;
  }

  validateEmailAddress(email) {
    const regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  }

  getIntentDisplayName(message) {
    return message &&
      message[0] &&
      message[0].queryResult &&
      message[0].queryResult.intent &&
      message[0].queryResult.intent.displayName
      ? message[0].queryResult.intent.displayName
      : "";
  }

  preprocessInput(message) {
    if (message.indexOf("can I sign up?") !== -1) {
      this.calculateIEP();
      if (
        current_date > new Date(state.stateJson.startIEP) &&
        current_date < new Date(state.stateJson.endIEP)
      ) {
        return message + "-IEP Present";
      } else if (current_date.getMonth() < 3) {
        return message + "-GEP Present";
      } else {
        return message + "-GEP Future";
      }
    } else if (
      !state.stateJson.olderthan65 &&
      state.convJson.bot &&
      ((state.convJson.bot.indexOf("already enrolled in Medicare?") !== -1 &&
        message === "Neither Part A nor Part B") ||
        (state.convJson.bot.indexOf(
          "Select any one of these options that's relevant to"
        ) !== -1 &&
          message === "None of these") ||
        (state.convJson.bot.indexOf("differently-abled?") !== -1 &&
          message.toLowerCase() === "No".toLowerCase()))
    ) {
      this.calculateIEP();
      if (
        current_date > new Date(state.stateJson.startIEP) &&
        current_date < new Date(state.stateJson.endIEP)
      ) {
        return message + "-IEP Present";
      } else {
        return message + "-IEP Future";
      }
    }
    return message;
  }

  processOutput(text, intentName) {
    if (text.indexOf("<# MMM, YYYY> to <# MMM, YYYY>") !== -1) {
      text = text.replace(
        "<# MMM, YYYY> to <# MMM, YYYY>",
        state.stateJson.startIEP + " to " + state.stateJson.endIEP
      );
    }
    if (
      text.indexOf("<# MMM, 2019>") !== -1 ||
      text.indexOf("<# MMM, YYYY>") !== -1
    ) {
      if (
        current_date < new Date(state.stateJson.startIEP) &&
        new Date(state.stateJson.startIEP) < new Date(state.stateJson.startGEP)
      ) {
        text = text.replace(/GEP/g, "IEP");
        text = text.replace("<# MMM, 2019>", state.stateJson.startIEP);
        text = text.replace("<# MMM, YYYY>", state.stateJson.startIEP);
      } else {
        text = text.replace(
          "<# MMM, 2019>",
          " 24 Dec, " + new Date().getFullYear()
        );
        text = text.replace(
          "<# MMM, YYYY>",
          " 24 Dec, " + new Date().getFullYear()
        );
      }
    }
    if (text.indexOf("<yyyy>") !== -1 || text.indexOf("2019") !== -1) {
      text = text.replace("<yyyy>", new Date().getFullYear());
      text = text.replace("2019", new Date().getFullYear());
    }
    if(text.indexOf("<count>")!== -1){
      text = text.replace("<count>", state.stateJson.resourceCount);
    }
  
    text = text.replace("<city>", state.stateJson.ec_city);
    text = text.replace("<state name>", state.stateJson.ec_state);

    //Add market place links
    if (text.indexOf("#Marketplacelink#") !== -1) {
      const link = marketplaceDtls[state.stateJson.state]?.link;
      text = text.replace("#Marketplacelink#", link);
    }

    //make email clickable
    let emailsArray = text.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    );
    if (emailsArray) {
      emailsArray.forEach((email) => {
        const emailhtml = '<a href="mailto:' + email + '">' + email + "</a>";
        text = text.replace(email, emailhtml);
      });
    }

    //make website link clickable
    var websiteArray = text.match(/\bhttps?:\/\/\S+/gi);
    if (websiteArray) {
      websiteArray.forEach((link) => {
        const linkhtml =
          "<a href='" + link + "' target='blank'>" + link + "</a>";
        text = text.replace(link, linkhtml);
      });
    }

    if (text.indexOf("keep information ready") !== -1) {
      text = text.replace(
        "information",
        "<a href='https://www.ssa.gov/hlp/isba/10/isba-checklist.pdf' target='blank'>information</a>"
      );
    }

    Object.keys(linkSuggestionMap).forEach((key) => {
      if (text.includes(key)) {
        text = text.replace(
          key,
          `<a href='${linkSuggestionMap[key]}' target='blank'>${key}</a>`
        );
        // `<div class="suggestion" id="suggestion-link"><a href="${linkSuggestionMap[item.toLowerCase()]}" target="blank">${item}</a></div>`;
      }
    });

    //Remove text inside <remove> tag
    text = text.replace(/<remove>[a-zA-Z0-9]*<remove>/g, "");

    if (intentName !== "Get name") {
      text = text.replace(/Chris/g, state.stateJson.user_name);
    }

    if (
      state.stateJson.beneficiaryType &&
      state.stateJson.beneficiaryType.includes("representative")
    ) {
      text = this.replaceBenificiaryWords(text);
    }

    return text;
  }

  getCommunityUrl(id) {
    if (id) {
      let topicId = community.pages.topic.links[id];
      if (topicId) {
        return `${community.url}${community.pages.topic.url}`.replace(
          "#topicId",
          topicId
        );
      }
      let questionId = community.pages.topic.question.links[id];
      if (questionId) {
        return `${community.url}${community.pages.topic.question.url}`.replace(
          "#questionId",
          questionId
        );
      }
    }
  }
  addLinks(text, isFirst) {
    if (isFirst) {
      state.firstOccurance = false;
    }
    const regex = /(<([^>]+)>)/gi;
    let textCopy = text;
    text = text.replace(regex, "");
    Object.keys(tooltipMap).forEach((key,index) => {
      if (text.toLowerCase().indexOf(key.toLowerCase()) !== -1) {
        const startIndex = text.toLowerCase().indexOf(key.toLowerCase());
        if (startIndex !== -1) {
          const linkHtml =
            "<span class='description-link' id='" +
            index +
            "'>" +
            text.substr(startIndex, key.length) +
            "</span>";
          textCopy = textCopy.replace(
            text.substr(startIndex, key.length),
            linkHtml
          );
          text = text.replace(text.substr(startIndex, key.length), "");
        }
      }
    });
    return textCopy;
  }

  replaceBenificiaryWords(text) {
    const msgArr = text.split("<br>");
    msgArr.forEach((msg, index) => {
      let temp = msg;
      Object.keys(beneficiaryMap.replaceText).forEach((key) => {
        if (temp.indexOf(key) !== -1) {
          msg = msg.split(key).join(beneficiaryMap.replaceText[key]);
          temp = temp.split(key).join("");
        }
      });
      msgArr[index] = msg;
    });
    text = msgArr.join("<br>");

    Object.keys(beneficiaryMap.replaceText2).forEach((key) => {
      let firstOccuranceIndex = text.search(key);
      if (firstOccuranceIndex !== -1) {
        if (!this.firstOccurance) firstOccuranceIndex = +key.length;
        this.firstOccurance = true;
        text =
          text.substr(0, firstOccuranceIndex) +
          text
            .slice(firstOccuranceIndex)
            .split(key)
            .join(beneficiaryMap.replaceText2[key]);
      }
    });
    return text;
  }

  calculateIEP() {
    if (state.stateJson.my_dob) {
      let dob = new Date(state.stateJson.my_dob);
      let dob65 = new Date(
        new Date(state.stateJson.my_dob).setYear(
          new Date(state.stateJson.my_dob).getFullYear() + 65
        )
      );
      let dob65temp = new Date(
        new Date(state.stateJson.my_dob).setYear(
          new Date(state.stateJson.my_dob).getFullYear() + 65
        )
      );

      let startDate = new Date(dob65.setMonth(dob65.getMonth() - 2));
      let endDate = new Date(dob65temp.setMonth(dob65temp.getMonth() + 2));
      state.stateJson.startIEP =
        dp_month_array_full[startDate.getMonth()] +
        " " +
        startDate.getDate() +
        ", " +
        startDate.getFullYear();
      state.stateJson.endIEP =
        dp_month_array_full[endDate.getMonth()] +
        " " +
        endDate.getDate() +
        ", " +
        endDate.getFullYear();
    }
  }

  setGEP() {
    if (current_date.getMonth() > 2) {
      const year = new Date().getFullYear() + 1;
      state.stateJson.startGEP = "Jan 1 " + year;
      state.stateJson.endGEP = "Mar 31 " + year;
    }
  }

  setInsuranceList(stateName) {
    const stateUpper = stateName.toUpperCase().toString();
    state.privateInsurance = privateInsuranceList[stateUpper];
  }

  enableButtonClick(jElement) {
    if (!jElement) return;
    jElement.css({ "pointer-events": "" });
    jElement.css({ opacity: 1 });
  }

  disableButtonClick(jElement) {
    if (!jElement) return;
    jElement.css({ "pointer-events": "none" });
    jElement.css({ opacity: 0.5 });
  }

  formatPhone(phoneNumber) {
    if (phoneNumber) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 $3");
    }
    return "";
  }

  textTransform(title) {
    if (title) {
      return title
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return "";
  }

  static getInstance() {
    if (this.util) {
      return this.util;
    } else {
      this.util = new Util();
      return this.util;
    }
  }
}

export function emailValid(email) {
  let isValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
  return isValid;
}

export function onlyAlphaCharacter(name) {
  let result = /^[a-zA-Z]+$/.test(name);
  return result;
}

export function removeCountrySplChar() {
  let specialChar = /[+]/g;
  return specialChar;
}

export function formateDate(val) {
  const date = new Date(val);
  return (
    date.getUTCMonth() +
    1 +
    "/" +
    date.getUTCDate() +
    "/" +
    date.getUTCFullYear()
  );
}

export function formateNumber(phone) {
  if (phone && phone.length == 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return null;
}

export function capitalize(s) {
  if (typeof s !== "string") {
    return "";
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => option,
});
